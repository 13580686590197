/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import 'semantic-ui-css/semantic.min.css';

import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Container,
  //   Divider,
  Grid,
  Header,
  Icon,
  //   Image,
  //   List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
  Card,
} from 'semantic-ui-react';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="HeyHand"
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as="h2"
      content="The delivery e-commerce platform for local businesses making their own local deliveries."
      inverted
      style={{
        fontSize: mobile ? '1.4em' : '1.5em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.0em',
        marginBottom: mobile ? '0.5em' : '1.5em',
      }}
    />
    <a href="#solution">
      <Icon name="angle down" size="huge" />
    </a>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 700, padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item as="a" active>
                  Home
                </Menu.Item>
                <Menu.Item as="a" href="#solution">
                  Solution
                </Menu.Item>
                <Menu.Item as="a" href="#product">
                  Product
                </Menu.Item>
                <Menu.Item as="a" href="#pricing">
                  Pricing
                </Menu.Item>
                <Menu.Item position="right">
                  <Button
                    as="a"
                    inverted={!fixed}
                    primary={fixed}
                    style={{ marginLeft: '0.5em' }}
                    href="https://forms.gle/Wvur95JKngkam7GKA"
                    target="_blank"
                  >
                    Book a Demo
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as="a" active>
              Home
            </Menu.Item>
            <Menu.Item as="a">Work</Menu.Item>
            <Menu.Item as="a">Company</Menu.Item>
            <Menu.Item as="a">Careers</Menu.Item>
            <Menu.Item as="a">Sign Up</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign="center"
              style={{ minHeight: 350, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size="large">
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name="sidebar" />
                    sidebar
                  </Menu.Item>
                  <Menu.Item position="right">
                    <Button
                      as="a"
                      inverted
                      style={{ marginLeft: '0.5em' }}
                      target="_blank"
                      href="https://forms.gle/Wvur95JKngkam7GKA"
                    >
                      Book a Demo
                    </Button>
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical id="solution">
      <Grid container stackable verticalAlign="top">
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as="h2" style={{ fontSize: '2em' }}>
              <Header.Subheader
                style={{ textTransform: 'uppercase', color: '#2185d0' }}
              >
                Problem
              </Header.Subheader>
              The big box e-commerce companies don't work for your business and
              delivery apps make you feel disconnected from your customers and
              committed to selling cookie cutter designs.
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              What if you could sell directly to your customers and still have
              the power of a marketplace? What if you could offer your
              innovative products locally while also earning income and
              recognition for your products nationally?
            </p>
          </Grid.Column>
          <Grid.Column width={6}>
            <Header as="h2" style={{ fontSize: '2em' }}>
              <Header.Subheader
                style={{ textTransform: 'uppercase', color: '#2185d0' }}
              >
                Solutions
              </Header.Subheader>
            </Header>
            <Card>
              <Card.Content>
                <Card.Header>
                  Locals Deliver <Icon name="truck" />
                </Card.Header>
                <Card.Description style={{ fontSize: '1.1em' }}>
                  We built a platform that makes it easy for businesses to offer
                  delivery of crafted goods and services within a local delivery
                  area.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>
                  Save Money & Customers <Icon name="group" />
                </Card.Header>
                <Card.Description style={{ fontSize: '1.1em' }}>
                  You save money by not paying out a fat fee or your customers
                  to ecommerce giants and delivery apps.
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>
                  Make Money <Icon name="picture" />
                </Card.Header>
                <Card.Description style={{ fontSize: '1.1em' }}>
                  You can now earn a new form of income by offering your
                  products for resale by businesses in other markets.
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical id="product">
      <Grid container stackable verticalAlign="middle" textAlign="center">
        <Grid.Row>
          <Grid.Column>
            <Header as="h2" style={{ fontSize: '2em' }}>
              <Header.Subheader
                style={{ textTransform: 'uppercase', color: '#2185d0' }}
              >
                Product
              </Header.Subheader>
              Why customers will choose you with HeyHand
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid centered container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={8} floated="center">
            <Header as="h3" style={{ fontSize: '1.5em' }}>
              Search for local businesses
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Customers want to support local businesses. They want to know
              where their products are coming from and who is making them. They
              can book on the HeyHand marketplace or through your HeyHand-hosted
              website.
            </p>
            <Header as="h3" style={{ fontSize: '1.5em' }}>
              Review locally-made products and services
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Listings make it easy for customers to find the products and
              services offered within your delivery area. Customers can also see
              if your products and services are offered elsewhere in the
              country.
            </p>
            <Header as="h3" style={{ fontSize: '1.5em' }}>
              Place an order!
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Customers can place an order immediately. They can also choose to
              pick up their order or have it delivered within your delivery area
              and schedule. If your product is purchased for delivery outside
              your market, you earn a commission on the sale!
            </p>
          </Grid.Column>
          {/* <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src='/images/wireframe/white-image.png' />
          </Grid.Column> */}
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical id="pricing">
      <Grid container stackable verticalAlign="middle" textAlign="center">
        <Grid.Row>
          <Grid.Column>
            <Header as="h2" style={{ fontSize: '2em' }}>
              <Header.Subheader
                style={{ textTransform: 'uppercase', color: '#2185d0' }}
              >
                Pricing
              </Header.Subheader>
              A no-frills, low cost e-commerce platform that makes money when
              you make money.
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid container stackable verticalAlign="middle" textAlign="center">
        <Grid.Row>
          <Grid.Column width={14}>
            <Card.Group itemsPerRow={3} stackable>
              <Card>
                <Card.Content>
                  <Card.Header style={{ padding: '1.5em 0' }}>
                    90% on every sale
                  </Card.Header>
                  <Card.Description
                    style={{ fontSize: '1.1em', textAlign: 'justify' }}
                  >
                    You keep 90% on all orders placed on HeyHand. 10% goes to
                    HeyHand to cover our costs including transaction fees,
                    product licensing, hosting, and development.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header style={{ padding: '1.5em 0' }}>
                    2.5% on your products sold outside your market
                  </Card.Header>
                  <Card.Description
                    style={{ fontSize: '1.1em', textAlign: 'justify' }}
                  >
                    Allow other businesses to sell your products in their
                    markets. You earn a 2.5% commission on all sales.
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Card.Header style={{ padding: '1.5em 0' }}>
                    Get a quote
                  </Card.Header>
                  <Card.Description
                    style={{ fontSize: '1.1em', textAlign: 'justify' }}
                  >
                    If you are running a delivery service and want to use
                    HeyHand to power your business, we can work with you to
                    create a custom plan.
                  </Card.Description>
                </Card.Content>
              </Card>
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <Header as="h3" style={{ fontSize: '2em' }}>
          Hey, let's get started!
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Schedule a one-on-one demo and conversation on how HeyHand can help
          you grow your business.
        </p>

        {/* button with link: */}
        <Button
          primary
          as="a"
          size="large"
          href="https://forms.gle/Wvur95JKngkam7GKA"
          target="_blank"
        >
          Book a Demo
        </Button>
      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header as="h4" inverted>
                Contact Us
              </Header>
              <p>hello@heyhand.com</p>
            </Grid.Column>
            <Grid.Column width={12} textAlign="right">
              <Header as="h4" inverted textAlign="right">
                HeyHand - For Handmade Delivery
              </Header>
              <p>Made in Seattle</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default HomepageLayout;
